import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DateOptions} from '../../interfaces/date-options';
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  setDate,
  setDay,
  startOfDay,
  subMonths
} from 'date-fns';
import {VbSlotResponse} from '@appyvet/vetbooker-definitions/dist/bookings';

interface Day {
  date: Date;
  name: string;
  number: number;
  isCurrentMonth: boolean;
  isDisallowed: boolean;
  isToday: boolean;
  availability: number;
}

interface Week {
  days: Day[];
}

@Component({
  selector: 'app-new-calendar',
  templateUrl: './new-calendar.component.html',
  styleUrls: ['./new-calendar.component.scss']
})
export class NewCalendarComponent implements OnInit, OnChanges {
  @Input() dateOptions: DateOptions;
  @Input() isRequestType: boolean;
  @Input() loading: boolean;
  @Input() selectedDate: Date;
  @Input() searchResults: VbSlotResponse[];
  @Output() setSelectedDate = new EventEmitter<Date>();
  @Output() monthChange = new EventEmitter<Date>();

  isGroomRoom: boolean;
  lastDate: Date;
  month: Date;
  textColor: string;
  weeks: Week[] = [];
  isFirstMonth: boolean;
  today: Date;
  isLastMonth: boolean;
  private firstAvailable: Date;

  constructor() {
  }

  createCalendar() {
    this.textColor = '#FFFFFF';
    this.today = new Date();
    this.firstAvailable = startOfDay(this.dateOptions.firstDate) || new Date(
      addDays(this.today, this.dateOptions.minDaysBeforeBooking).setHours(0, 0, 0));
    this.selectedDate = this.selectedDate || addDays(this.firstAvailable, 1);
    if (this.dateOptions.maxDays > 0) {
      this.lastDate = this.dateOptions.lastDate || addDays(this.today, this.dateOptions.maxDays);
      this.isLastMonth = isSameMonth(this.selectedDate, this.lastDate);
    }
    this.month = new Date(this.selectedDate.valueOf());
    const start = setDay(setDate(this.month, 1), 0);
    this._buildMonth(start, this.month);
    this.isFirstMonth = isSameMonth(this.selectedDate, this.today);
  }

  select(day: Day) {
    let dayDisallowed = false;
    if (this.dateOptions.satDisallowed && day.date.getDay() === 6) {
      dayDisallowed = true;
    }
    if (this.dateOptions.sunDisallowed && day.date.getDay() === 0) {
      dayDisallowed = true;
    }
    if (this.dateOptions.maxDays > 0) {
      if (isAfter(day.date, this.lastDate)) {
        dayDisallowed = true;
      }
    }
    if (isBefore(day.date, this.firstAvailable)) {
      dayDisallowed = true;
    }
    if (!dayDisallowed && !day.isDisallowed) {
      this.selectedDate = day.date;
      this.setSelectedDate.emit(this.selectedDate);
    }
  }

  next() {
    const nextMonth = setDate(addMonths(this.month, 1), 1);
    const next = setDay(nextMonth, 0);
    this.month = nextMonth;
    this.monthChange.emit(this.month);
    this._buildMonth(next, this.month);
    this.isFirstMonth = isSameMonth(this.month, this.today);
    this.isLastMonth = isSameMonth(this.month, this.lastDate);
  }

  previous() {
    const prevMonth = setDate(subMonths(this.month, 1), 1);
    const previous = setDay(prevMonth, 0);
    this.month = prevMonth;
    this._buildMonth(previous, this.month);
    this.isFirstMonth = isSameMonth(this.month, this.firstAvailable);
    this.monthChange.emit(this.isFirstMonth ? this.firstAvailable : this.month);
  }

  _buildMonth(start: Date, month: Date) {
    this.weeks = [];
    let done = false;
    let date = new Date(start.valueOf());
    let monthIndex = date.getMonth();
    let count = 0;
    while (!done) {
      this.weeks.push({days: this._buildWeek(date, month)});
      date = addWeeks(date, 1);
      done = count++ > 2 && monthIndex !== date.getMonth();
      monthIndex = date.getMonth();
    }
  }

  _buildWeek(date: Date, originalDate: Date) {
    const days: Day[] = [];
    for (let i = 0; i < 7; i++) {
      let availability = 0;
      this.searchResults.forEach(result => {
        if (isSameDay(result.date, date) && !result.isPublicHoliday) {
          result.clipboardResults.forEach(slot => {
            availability += slot.clipboardItems.length;
          });
        }
      });
      let dayDisallowed = false;
      if (this.dateOptions.satDisallowed && date.getDay() === 6) {
        dayDisallowed = true;
      }
      if (this.dateOptions.sunDisallowed && date.getDay() === 0) {
        dayDisallowed = true;
      }
      if (this.dateOptions.maxDays > 0) {
        if (isAfter(date, this.lastDate)) {
          dayDisallowed = true;
        }
      }
      if (isBefore(date, this.firstAvailable)) {
        dayDisallowed = true;
      }
      days.push({
        name: format(date, 'd'),
        number: date.getDate(),
        isCurrentMonth: isSameMonth(date, originalDate),
        isDisallowed: dayDisallowed || availability === 0,
        isToday: isSameDay(date, this.today),
        date,
        availability,
      });
      date = addDays(date, 1);
    }
    return days;
  }

  getAvailabilityColor(availability: number) {
    if (availability > 4) {
      return '#4CBB17';
    } else if (availability > 0) {
      return '#FF7800';
    } else {
      return '#d3d3d3';
    }
    // else if (availability > 0) {
    //   return '#E60000';
    // }
  }

  ngOnInit(): void {
    if (this.dateOptions) {
      this.createCalendar();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createCalendar();
  }
}
